<template>
	<a-space direction="vertical">
		<a-card title="Banner管理">
			<a-button slot="extra" type="primary" @click="onCreate">新增</a-button>
			<a-table :data-source="lists.data" :pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }"
			 @change="onPageChange" :scroll="{ x: 'max-content' }">
				<a-table-column key="id" title="ID" data-index="id" />
				<a-table-column key="title" title="标题" data-index="title" />
				<a-table-column key="imageUrl" title="图片" data-index="imageUrl">
					<template slot-scope="imageUrl, record">
						<a-avatar shape="square" :size="64" style="width:192px;height:64px" :src="imageUrl" />
					</template>
				</a-table-column>
				<a-table-column key="typeStr" title="跳转类型" data-index="typeStr" />
				<a-table-column key="value" title="H5跳转地址" data-index="value">
					<template slot-scope="value, record">
						<a-tooltip placement="top">
							<template slot="title">
								<span>{{value}}</span>
							</template>
							<span>跳转地址</span>
						</a-tooltip>
					</template>
				</a-table-column>
				<a-table-column key="status" title="状态" data-index="status">
					<template slot-scope="status, record">
						<a-tag color="orange" v-if="status == 1">
							{{record.statusStr}}
						</a-tag>
						<a-tag color="cyan" v-else-if="status == 0">
							{{record.statusStr}}
						</a-tag>
					</template>
				</a-table-column>
				<a-table-column key="positionStr" title="展示位置" data-index="positionStr" />
				<a-table-column key="sort" title="排序" data-index="sort" />
				<a-table-column key="action" title="操作" fixed="right">
					<template slot-scope="text, record">
						<a-space>
							<a-button size="small" @click="onView(record)">详情</a-button>
							<a-button size="small" @click="onEdit(record)">编辑</a-button>
						</a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>
	</a-space>
</template>

<script>
	import moment from 'moment'

	const formatter = 'YYYY-MM-DD'
	const DEFAULT_QUERY = {
		page: 1,
		size: 10,
		status: undefined,
		type: undefined,
		mobile: undefined,
		beginTime: undefined,
		endTime: undefined,
	}

	export default {
		data() {
			return {
				query: Object.assign({}, DEFAULT_QUERY),
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0,
				},
				modal: {
					visible: false,
					text: "",
					data: {}
				},
			};
		},
		computed: {
			selectedDateRange() {
				const {
					beginTime,
					endTime
				} = this.query
				if (beginTime && endTime) {
					return [moment(beginTime, formatter), moment(endTime, formatter)]
				} else {
					return null
				}
			}
		},
		mounted() {
			this.getBanners()
		},
		methods: {
			async getBanners() {
				const response = await this.$api.get('/banner_list', {
					params: this.query
				});
				if (response && response.code == 200) {
					response.data.data = response.data.data.map(v => {
						v.payUrls = String(v.payUrl).split(',')
						return v
					})
					this.lists = Object.assign(this.lists, response.data)
				}
			},
			async onPageChange(pagination) {
				this.query.page = pagination.current
				this.query.size = pagination.pageSize
				this.getBanners();
			},
			async onSearch() {
				this.query.page = 1
				this.getBanners()
			},
			async onReset() {
				this.query = Object.assign({}, DEFAULT_QUERY)
				this.getBanners()
			},
			async onChange(o, dateStr) {
				if (dateStr.length === 2) {
					this.query.beginTime = dateStr[0]
					this.query.endTime = dateStr[1]
				} else {
					this.query.beginTime = undefined
					this.query.endTime = undefined
				}
			},
			async onCreate() {
				this.$router.push('/banners/create')
			},
			async onView(record) {
				this.$router.push(`/banners/${record.id}`)
			},
			async onEdit(record) {
				this.$router.push(`/banners/${record.id}/edit`)
			}
		},
	};
</script>
